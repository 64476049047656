<template>
  <div id="dashboard-analytics" class="dashbaord-analytics" :class="{ 'pt-3': isMobile }">
    <div>
      <div class="md:mt-0 ml-0">
        <div class="mt-5 mb-5 dashboard-toolbar">
          <vega-reports v-if="HAS_VEGA_REPORT_ACCESS" class="mr-4" :vegaWidgetList="vegaWidgetList" />

          <call-center-csv-reports v-if="HAS_CALL_CENTER_REPORT_ACCESS" class="mr-4" :dateRange="dateRange" />

          <div class="dashboard-toolbar-filters" :class="isLoading ? 'disabled' : ''">
            <filters
              :dialogList="widgetList"
              :campaignList="campaignList"
              :localeData="localeData"
              :selectedDialogs="selectedDialogs"
              :selectedCampaigns="selectedCampaigns"
              :key="filterKey"
              :isCampaignFilter="true"
              @campaignChanged="handleCampaignChange"
              @dialogChanged="handleDialogChange"
              @dateRangeChanged="handleDateRangeChange"
              @accountChanged="handleAccountChange"
            />
          </div>

          <div class="dashboard-toolbar-controls">
            <div class="dashboard-toolbar-controls-refresh">
              <button class="dashboard-toolbar-controls-refresh-btn" :class="disableRefreshBtn ? 'disabled' : ''" @click.stop="refreshData">
                <span class="dashboard-toolbar-controls-refresh-btn-wording">
                  {{ $t('views.home.refreshData') }}
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="md:mt-0 ml-0" style="margin-bottom: 20px">
        <div class="pt-5 analytics-container--right-grow1 vega-analytics-container">
          <div class="ml-0 margin-rt">
            <VegaAnalyticsSummary
              :series="vegaVisitorsCountPerDayChart"
              :seriesShowCountPerDay="showCountPerDay"
              :weekdays="localeData.daysOfWeek"
              :isLoading="isVegaVisitorsCountPerDayDataLoading || isTotalVisitorsCountLoading || isDialogShowsPerDayOfWeekLoading"
              @activeViewChanged="changeActiveView"
            />
          </div>
        </div>
      </div>

      <div style="margin-bottom: 20px">
        <analytics-container
          :isMobile="isMobile"
          :isTotalVisitorsCountLoading="isTotalVisitorsCountLoading"
          :isRequestsLoading="isRequestsLoading"
          :isSessionsLoading="isSessionsLoading"
          :isCampaignRequestsLoading="isCampaignRequestsLoading"
          :isCampaignSessionsLoading="isCampaignSessionsLoading"
          :topVisitors="topVisitors"
          :isVisitorsLocationLoading="isVisitorsLocationLoading"
          :agents="agentsData"
          :isAgentsLoading="isAgentsLoading"
          :routeClicks="routeClicks"
          :isRouteClicksLoading="isRouteClicksLoading"
          :deviceData="deviceData"
          :isDeviceLoading="isDeviceLoading"
          :browserData="browserData"
          :browserDataTableConfig="browserDataTableCongig"
          :isBrowserDataLoading="isBrowserDataLoading"
          :topOrigins="topOrigins"
          :isOriginDataLoading="isOriginDataLoading"
          :topOriginsTableConfig="topOriginsTableCongig"
          :topReferers="topReferers"
          :isRefererDataLoading="isRefererDataLoading"
          :topReferersTableConfig="topReferersTableCongig"
          :sessionDuration="sessionDuration"
          :isSessionDurationLoading="isSessionDurationLoading"
          :shownContactForm="contactFormShownCount"
          :filledContactForm="contactFormFilledCount"
          :bookedContactForm="contactFormBookedAppointmentCount"
          :isContactFormLoading="isContactFormLoading"
          :endScreenFeedbackData="endScreenFeedbackData"
          :isEndScreenFeedbackDataLoading="isEndScreenFeedbackDataLoading"
          :visitorsRating="visitorsRating"
          :HAS_ADVANCED_DASHBOARD_ACCESS="HAS_ADVANCED_DASHBOARD_ACCESS"
          :isVegaConversationStatusDataLoading="isVegaConversationStatusDataLoading"
          :isVegaFilledContactFormStatusDataLoading="isVegaFilledContactFormStatusDataLoading"
          :isVegaAgentConversationRatingDataLoading="isVegaAgentConversationRatingDataLoading"
        />
      </div>

      <div class="ml-0">
        <vega-analytics
          :isMobile="isMobile"
          :isVegaConversationStatusDataLoading="isVegaConversationStatusDataLoading"
          :isVegaFilledContactFormStatusDataLoading="isVegaFilledContactFormStatusDataLoading"
          :isVegaAgentConversationRatingDataLoading="isVegaAgentConversationRatingDataLoading"
          :isVegaVisitorAnalyticsDataLoading="isVegaVisitorAnalyticsDataLoading"
          :isVegaScoresAnalyticsDataLoading="isVegaScoresAnalyticsDataLoading"
          :isVegaCampaignsDataLoading="isVegaCampaignsDataLoading"
          :isVegaVisitorScoreFlowDataLoading="isVegaVisitorScoreFlowDataLoading"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

import { generateDialogFilter, generateCampaignFilter, generateDialogCampaignFilter } from '@/helpers/query_builder'
import { commonFunctions } from '@/mixins/commonFunctions'
import { gen2function } from '@/mixins/gen2function'
import { mobile_check } from '@/mixins/mobile_check'
import i18n from '@/i18n/i18n.js'
import Filters from '@/views/home/components/Filters.vue'
import RequestsByHour from '@/views/home/components/RequestsByHour.vue'
import RequestsPerWeek from '@/views/home/components/RequestsPerWeek.vue'
import SessionsByHour from '@/views/home/components/SessionsByHour.vue'
import SessionsPerWeek from '@/views/home/components/SessionsPerWeek.vue'
import ShowByHour from '@/views/home/components/ShowByHour.vue'
import ShowsPerWeek from '@/views/home/components/ShowsPerWeek.vue'
import VegaMessage from '@/components/upgrade-messages/VegaMessage.vue'
import VegaAnalyticsSummary from '@/views/home/components/VegaAnalyticsSummary.vue'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

Date.prototype.addDays = function (days) {
  const date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date
}

export default {
  mixins: [commonFunctions, gen2function, mobile_check],
  data() {
    return {
      demoDataBqVisitorsCountPerDayWeek: require('@/assets/vega_demo_data/bq-visitors-count-per-day-week.json'),
      demoDataBqVisitorsCountPerDayDays: require('@/assets/vega_demo_data/bq-visitors-count-per-day-days.json'),
      demoDataBqCampaignAnalytics: require('@/assets/vega_demo_data/bq-campaign-analytics.json'),
      demoDataBqConversationRating: require('@/assets/vega_demo_data/bq-conversation-rating.json'),
      demoDataBqConversationStatus: require('@/assets/vega_demo_data/bq-conversation-status.json'),
      demoDataBqScoresAnalytics: require('@/assets/vega_demo_data/bq-scores-analytics.json'),
      demoDataBqVisitorAnalytics: require('@/assets/vega_demo_data/bq-visitor-analytics.json'),
      demoDataBqVisitorContacts: require('@/assets/vega_demo_data/bq-visitor-contacts.json'),

      demoDataGetAgentsDataBQ: require('@/assets/vega_demo_data/getAgentsDataBQ.json'),
      demoDataGetBrowsersDataBQ: require('@/assets/vega_demo_data/getBrowsersDataBQ.json'),
      demoDataGetCampaignSessionsCountDataBQ: require('@/assets/vega_demo_data/getCampaignSessionsCountDataBQ.json'),
      demoDataGetContactFormCountDataBQ: require('@/assets/vega_demo_data/getContactFormCountDataBQ.json'),
      demoDataGetDeviceDataBQ: require('@/assets/vega_demo_data/getDeviceDataBQ.json'),
      demoDataGetEndScreenFeedbackDataBQ: require('@/assets/vega_demo_data/getEndScreenFeedbackDataBQ.json'),
      demoDataGetRequestCountDataBQ: require('@/assets/vega_demo_data/getRequestCountDataBQ.json'),
      demoDataGetRouteClicksBQ: require('@/assets/vega_demo_data/getRouteClicksBQ.json'),
      demoDataGetSessionDurationDataBQ: require('@/assets/vega_demo_data/getSessionDurationDataBQ.json'),
      demoDataGetSessionsCountDataBQ: require('@/assets/vega_demo_data/getSessionsCountDataBQ.json'),
      demoDataGetShowCountDataBQ: require('@/assets/vega_demo_data/getShowCountDataBQ.json'),
      demoDataGetTopOriginsDataBQ: require('@/assets/vega_demo_data/getTopOriginsDataBQ.json'),
      demoDataGetTopReferersDataBQ: require('@/assets/vega_demo_data/getTopReferersDataBQ.json'),
      demoDataGetVisitorsCountDataBQ: require('@/assets/vega_demo_data/getVisitorsCountDataBQ.json'),
      demoDataGetVisitorsLocationsDataBQ: require('@/assets/vega_demo_data/getVisitorsLocationsDataBQ.json'),
      demoDataGetvisitorsrating: require('@/assets/vega_demo_data/getvisitorsrating.json'),

      key: Math.random().toString(36).substring(2, 15),

      isMobile: false,
      gcpFunctionToken: null,

      filterKey: Math.random().toString(36).substring(2, 15),
      vegaFilterKey: Math.random().toString(36).substring(2, 15),
      disableRefreshBtn: false,

      isTotalVisitorsCountLoading: false,
      isVisitorsLocationLoading: false,
      isAgentsLoading: false,
      isRouteClicksLoading: false,
      isDeviceLoading: false,
      isBrowserDataLoading: false,
      isOriginDataLoading: false,
      isRefererDataLoading: false,

      isDialogShowsPerDayOfWeekLoading: false,

      isDialogShowsLoading: false,
      isRequestsLoading: false,
      isCampaignRequestsLoading: false,
      isSessionsLoading: false,
      isCampaignSessionsLoading: false,
      isSessionDurationLoading: false,
      isContactFormLoading: false,
      isFilledContactFormLoading: false,
      isEndScreenFeedbackDataLoading: false,
      isVisitorsRatingLoading: false,

      isVegaAgentConversationRatingDataLoading: false,
      isVegaConversationStatusDataLoading: false,
      isVegaVisitorAnalyticsDataLoading: false,
      isVegaVisitorsCountPerDayDataLoading: false,
      isVegaFilledContactFormStatusDataLoading: false,
      isVegaScoresAnalyticsDataLoading: false,
      isVegaCampaignsDataLoading: false,
      isVegaVisitorScoreFlowDataLoading: false,

      selectedDialogs: [],
      selectedVegaDialogs: [],
      selectedCampaigns: [],

      vegaVisitorsCountPerDayChart: [
        {
          name: i18n.t('views.home.visitorsCountPerDay'),
          totalData: [],
          thresholdData: []
        }
      ],
      showCountPerHour: [
        {
          name: i18n.t('views.home.dialogShowCountPerHour'),
          data: []
        }
      ],
      showCountPerDay: [
        {
          name: i18n.t('views.home.dialogShowCountPerDay'),
          data: []
        }
      ],
      customerReqeuestsCountPerHour: [
        {
          name: i18n.t('views.home.customerReqeuestsCountPerHour'),
          data: []
        }
      ],
      customerReqeuestsCountPerDay: [
        {
          name: i18n.t('views.home.customerReqeuestsCountPerDay'),
          data: []
        }
      ],
      sessionsCountPerHour: [
        {
          name: i18n.t('views.home.sessionsCountPerHour'),
          data: []
        }
      ],
      sessionsCountPerDay: [
        {
          name: i18n.t('views.home.sessionsCountPerDay'),
          data: []
        }
      ],
      topReferersTableCongig: {
        cl1_name: 'views.home.topReferers',
        cl2_name: ''
      },
      topOriginsTableCongig: {
        cl1_name: 'views.home.topOrigins',
        cl2_name: ''
      },
      browserDataTableCongig: {
        cl1_name: 'views.home.browser',
        cl2_name: 'views.home.session'
      },
      localeData: {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: this.daysOfWeek(),
        daysOfWeekLong: this.daysOfWeekLong(),
        monthNames: this.monthNames(),
        firstDay: 0
      }
    }
  },
  components: {
    Filters,
    ShowByHour,
    RequestsByHour,
    SessionsByHour,
    ShowsPerWeek,
    RequestsPerWeek,
    SessionsPerWeek,
    CallCenterCsvReports: () => import('@/views/home/components/CallCenterCSVReports.vue'),
    VegaReports: () => import('@/views/home/components/VegaReports.vue'),
    AnalyticsContainer: () => import('@/views/home/components/AnalyticsContainer.vue'),
    VegaAnalytics: () => import('@/views/home/components/VegaAnalytics.vue'),
    VegaMessage,
    VegaAnalyticsSummary
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',

      lastUpdated: 'dashboard/lastUpdated',
      dateRange: 'dashboard/dateRange',
      dialogList: 'dashboard/dialogList',
      campaignList: 'dashboard/campaignList',
      deviceData: 'dashboard/deviceData',
      browserData: 'dashboard/browserData',
      agentsData: 'dashboard/agentsData',
      routeClicks: 'dashboard/routeClicks',
      topReferers: 'dashboard/topReferers',
      topOrigins: 'dashboard/topOrigins',
      totalVisitors: 'dashboard/totalVisitors',
      topVisitors: 'dashboard/topVisitors',
      uniqueVisitors: 'dashboard/uniqueVisitors',
      returningVisitors: 'dashboard/returningVisitors',
      contactFormShownCount: 'dashboard/contactFormShownCount',
      contactFormFilledCount: 'dashboard/contactFormFilledCount',
      contactFormBookedAppointmentCount: 'dashboard/contactFormBookedAppointmentCount',
      endScreenFeedbackData: 'dashboard/endScreenFeedbackData',
      visitorsRating: 'dashboard/visitorsRating',
      showsCount: 'dashboard/showsCount',
      requestCount: 'dashboard/requestCount',
      chatRequestCount: 'dashboard/chatRequestCount',
      audioRequestCount: 'dashboard/audioRequestCount',
      videoRequestCount: 'dashboard/videoRequestCount',
      sessionsCount: 'dashboard/sessionsCount',
      chatSessionCount: 'dashboard/chatSessionCount',
      audioSessionCount: 'dashboard/audioSessionCount',
      videoSessionCount: 'dashboard/videoSessionCount',
      sessionDuration: 'dashboard/sessionDuration',
      vegaVisitorsCountPerDay: 'dashboard/vegaVisitorsCountPerDay',

      showsData: 'dashboard/showsData',
      requestsData: 'dashboard/requestsData',
      sessionsData: 'dashboard/sessionsData',

      campaignShowsCount: 'dashboard/campaignShowsCount',
      campaignRequestCount: 'dashboard/campaignRequestCount',
      campaignChatRequestCount: 'dashboard/campaignChatRequestCount',
      campaignAudioRequestCount: 'dashboard/campaignAudioRequestCount',
      campaignVideoRequestCount: 'dashboard/campaignVideoRequestCount',
      campaignSessionsCount: 'dashboard/campaignSessionsCount',
      campaignChatSessionCount: 'dashboard/campaignChatSessionCount',
      campaignAudioSessionCount: 'dashboard/campaignAudioSessionCount',
      campaignVideoSessionCount: 'dashboard/campaignVideoSessionCount',
      campaignSessionDuration: 'dashboard/campaignSessionDuration',

      campaignShowsData: 'dashboard/campaignShowsData',
      campaignRequestsData: 'dashboard/campaignRequestsData',
      campaignSessionsData: 'dashboard/campaignSessionsData',

      vegaDataFetched: 'dashboard/vegaDataFetched',

      widgetsAssigned: 'widgetsAssigned',
      widgetsAssignedKey: 'widgetsAssignedKey',
      apiKeys: 'apiKeys',
      selectedAccount: 'selectedAccount',
      selectedAccountApiKeys: 'selectedAccountApiKeys',

      activeChartViewRangeOnAnalyticsSummary: 'dashboard/activeChartViewRangeOnAnalyticsSummary',

      isCollapsedCampaignsBlock: 'dashboard/isCollapsedCampaignsBlock',
      isFetchedCampaignsBlockData: 'dashboard/isFetchedCampaignsBlockData',
      isCollapsedVisitorAnalyticsBlock: 'dashboard/isCollapsedVisitorAnalyticsBlock',
      isCollapsedWebsiteAnalyticsBlock: 'dashboard/isCollapsedWebsiteAnalyticsBlock',
      isCollapsedVisitorsJourneysBlock: 'dashboard/isCollapsedVisitorsJourneysBlock',
      isFetchedVisitorAnalyticsBlockData: 'dashboard/isFetchedVisitorAnalyticsBlockData',
      isFetchedWebsiteAnalyticsBlockData: 'dashboard/isFetchedWebsiteAnalyticsBlockData',
      isFetchedVisitorsJourneysBlockData: 'dashboard/isFetchedVisitorsJourneysBlockData',
      isCollapsedCommunicationAnalyticsBlock: 'dashboard/isCollapsedCommunicationAnalyticsBlock',
      isFetchedCommunicationAnalyticsBlockData: 'dashboard/isFetchedCommunicationAnalyticsBlockData',
      selectedVegaUserFlow: 'dashboard/selectedVegaUserFlow'
    }),

    selectedAccountId() {
      return this.selectedAccount ? this.selectedAccount.id : this.activeUserInfo.company
    },

    widgetList() {
      if (this.activeUserInfo && this.activeUserInfo.role === 'admin') {
        return this.dialogList
      }
      if (!this.widgetsAssigned || this.widgetsAssigned.length === 0) {
        return this.dialogList
      }
      return this.dialogList.filter((x) => {
        return this.widgetsAssigned.includes(x.dialogId)
      })
    },

    vegaWidgetList() {
      if (this.activeUserInfo && this.activeUserInfo.role === 'admin') {
        return this.dialogList.filter((dialog) => dialog.isVegaWidget)
      }
      if (!this.widgetsAssigned || this.widgetsAssigned.length === 0) {
        return this.dialogList.filter((dialog) => dialog.isVegaWidget)
      }

      return this.dialogList.filter((dialog) => this.widgetsAssigned.includes(dialog.dialogId) && dialog.isVegaWidget)
    },

    HAS_ADVANCED_DASHBOARD_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('dashboard') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    HAS_CALL_CENTER_REPORT_ACCESS() {
      return this.company && this.company.isCallCenter && this.activeUserInfo && this.activeUserInfo.role === 'admin'
    },
    HAS_VEGA_REPORT_ACCESS() {
      return this.activeUserInfo && this.activeUserInfo.superadmin
    },
    HAS_VEGA_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('vega') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },
    isLoading() {
      return (
        this.isVegaAgentConversationRatingDataLoading ||
        this.isVegaConversationStatusDataLoading ||
        this.isVegaVisitorAnalyticsDataLoading ||
        this.isVegaVisitorsCountPerDayDataLoading ||
        this.isVegaFilledContactFormStatusDataLoading ||
        this.isVegaScoresAnalyticsDataLoading ||
        this.isVegaCampaignsDataLoading ||
        this.isVegaVisitorScoreFlowDataLoading ||
        this.isVisitorsLocationLoading ||
        this.isAgentsLoading ||
        this.isRouteClicksLoading ||
        this.isDeviceLoading ||
        this.isBrowserDataLoading ||
        this.isOriginDataLoading ||
        this.isRefererDataLoading ||
        this.isEndScreenFeedbackDataLoading ||
        this.isDialogShowsLoading ||
        this.isDialogShowsPerDayOfWeekLoading ||
        this.isRequestsLoading ||
        this.isSessionsLoading ||
        this.isSessionDurationLoading ||
        this.isShownContactFormLoading ||
        this.isTotalVisitorsCountLoading
      )
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    ranges() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const todayNight = new Date()
      todayNight.setDate(today.getDate())
      todayNight.setHours(23, 59, 59, 999)

      const yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)
      const yesterdayNight = new Date()
      yesterdayNight.setDate(today.getDate() - 1)
      yesterdayNight.setHours(23, 59, 59, 999)

      const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
      const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      thisMonthEnd.setHours(23, 59, 59, 999)

      return {
        Today: [today, todayNight],
        Yesterday: [yesterday, yesterdayNight],
        'Last week': [lastWeek, today],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'Last year': [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)]
      }
    },
    startOfDay() {
      let startDate = new Date()

      if (this.dateRange && this.dateRange.startDate) {
        startDate = this.dateRange.startDate
      } else if (this.ranges['Last month'] && this.ranges['Last month'][0]) {
        startDate = this.ranges['Last month'][0]
      }

      return dayjs(startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss UTC')
    },
    endOfDay() {
      let endDate = new Date()

      if (this.dateRange && this.dateRange.endDate) {
        endDate = this.dateRange.endDate
      } else if (this.ranges['Today'] && this.ranges['Today'][1]) {
        endDate = this.ranges['Today'][1]
      }

      return dayjs(endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss UTC')
    }
  },

  async beforeMount() {
    const idTokenResult = await this.$firebase.auth().currentUser.getIdTokenResult()
    if (idTokenResult && idTokenResult.token) {
      this.gcpFunctionToken = idTokenResult.token
    }

    if (!this.dateRange.startDate || !this.dateRange.endDate) {
      this.updateDateRange({
        endDate: `${dayjs(this.ranges['Today'][1]).format('ddd MMM D YYYY h:mm Z')}`,
        startDate: `${dayjs(this.ranges['Last month'][0]).format('ddd MMM D YYYY h:mm Z')}`
      })
    }

    await this.getDialogsList()
    await this.getCampaignsList()

    if (!this.lastUpdated) {
      this.updateLastUpdated(dayjs().format())
    }
  },

  watch: {
    '$i18n.locale'() {
      this.localeData = {
        direction: 'ltr',
        format: 'mm/dd/yyyy',
        separator: ' - ',
        daysOfWeek: this.daysOfWeek(),
        daysOfWeekLong: this.daysOfWeekLong(),
        monthNames: this.monthNames(),
        firstDay: 0
      }

      this.filterKey = Math.random().toString(36).substring(2, 15)
    },
    async widgetsAssignedKey() {
      if (this.activeUserInfo && this.activeUserInfo.role === 'agent') {
        await this.getDialogsList()
        await this.getCampaignsList()
        await this.refreshData()
      }
    },

    'company.name'() {
      if (this.company.name && this.activeUserInfo && this.activeUserInfo.company) {
        this.setSelectedAccount({
          id: this.activeUserInfo.company,
          name: this.company.name
        })
      }
    },

    selectedVegaUserFlow() {
      this.getVegaDataMethod('bq-visitor-score-flow-page', 'updateVegaVisitorScoreFlowData', 'isVegaVisitorScoreFlowDataLoading', this.selectedVegaUserFlow.url)

      this.updateIsCollapsedVisitorsJourneysBlock(false)

      if (!this.isFetchedVisitorsJourneysBlockData) {
        this.updateIsFetchedVisitorsJourneysBlockData(true)
      }
    },

    'selectedAccountApiKeys.vega'() {
      if (!this.vegaDataFetched.vegaAnalytics && this.company && !this.company.isDemoAccount) {
        this.fetchOpenedData()
      }
    },

    'company.isDemoAccount'() {
      if (this.company && this.company.isDemoAccount) {
        this.fetchDemoVegaData()
      }
    }
  },

  created() {
    this.isMobile = this.mobileCheck()

    // trigger functions for collapsible sections

    this.$serverBus.$on('fetch-data-in-blocks', (data) => {
      if (
        this.selectedAccountApiKeys &&
        this.selectedAccountApiKeys.vega &&
        this.selectedAccountId &&
        ((this.company && !this.company.isDemoAccount && this.company.isVegaAccount && this.isVegaActivated) || this.activeUserInfo.superadmin)
      ) {
        // fucntions to fetch real vega data for collapsible sections

        if (data.query === 'bq-campaign-analytics') {
          if (!this.isFetchedCampaignsBlockData) {
            this.getVegaDataMethod('bq-campaign-analytics', 'updateVegaCampaignsData', 'isVegaCampaignsDataLoading')
          }
        } else if (data.query === 'bq-visitor-analytics') {
          if (!this.isFetchedVisitorAnalyticsBlockData) {
            this.getVegaDataMethod('bq-visitor-analytics', 'updateVegaVisitorAnalyticsData', 'isVegaVisitorAnalyticsDataLoading')
          }
        } else if (data.query === 'bq-scores-analytics') {
          if (!this.isFetchedWebsiteAnalyticsBlockData) {
            this.getVegaDataMethod('bq-scores-analytics', 'updateVegaScoresAnalyticsData', 'isVegaScoresAnalyticsDataLoading')
          }
        } else if (data.query === 'bq-communication-analytics') {
          if (!this.isFetchedCommunicationAnalyticsBlockData) {
            this.getVegaDataMethod('bq-visitor-contacts', 'updateVegaFilledContactFormStatusData', 'isVegaFilledContactFormStatusDataLoading')
            this.getVegaDataMethod('bq-conversation-status', 'updateVegaConversationStatusData', 'isVegaConversationStatusDataLoading')
            this.getVegaDataMethod('bq-conversation-rating', 'updateVegaAgentConversationRatingData', 'isVegaAgentConversationRatingDataLoading')

            setTimeout(() => {
              this.fetchGeneralData(true)
            }, 300)
          }
        }
      } else {
        // function to fetch real non vega data and demo vega data for collapsible sections

        if (data.query === 'bq-campaign-analytics') {
          if (!this.isFetchedCampaignsBlockData) {
            this.updateVegaCampaignsData(this.demoDataBqCampaignAnalytics)
          }
        } else if (data.query === 'bq-visitor-analytics') {
          if (!this.isFetchedVisitorAnalyticsBlockData) {
            this.updateVegaVisitorAnalyticsData(this.demoDataBqVisitorAnalytics)
          }
        } else if (data.query === 'bq-scores-analytics') {
          if (!this.isFetchedWebsiteAnalyticsBlockData) {
            this.updateVegaScoresAnalyticsData(this.demoDataBqScoresAnalytics)
          }
        } else if (data.query === 'bq-communication-analytics') {
          if (!this.isFetchedCommunicationAnalyticsBlockData) {
            this.updateVegaFilledContactFormStatusData(this.demoDataBqVisitorContacts)
            this.updateVegaConversationStatusData(this.demoDataBqConversationStatus)
            this.updateVegaAgentConversationRatingData(this.demoDataBqConversationRating)

            setTimeout(() => {
              this.fetchGeneralData(true)
            }, 300)
          }
        }
      }

      // default functions to toggle collapsible sections and fetching data

      if (data.query === 'bq-campaign-analytics') {
        this.updateIsCollapsedCampaignsBlock(!this.isCollapsedCampaignsBlock)

        if (!this.isFetchedCampaignsBlockData) {
          this.updateIsFetchedCampaignsBlockData(true)
        }
      } else if (data.query === 'bq-visitor-analytics') {
        this.updateIsCollapsedVisitorAnalyticsBlock(!this.isCollapsedVisitorAnalyticsBlock)

        if (!this.isFetchedVisitorAnalyticsBlockData) {
          this.updateIsFetchedVisitorAnalyticsBlockData(true)
        }
      } else if (data.query === 'bq-scores-analytics') {
        this.updateIsCollapsedWebsiteAnalyticsBlock(!this.isCollapsedWebsiteAnalyticsBlock)

        if (!this.isFetchedWebsiteAnalyticsBlockData) {
          this.updateIsFetchedWebsiteAnalyticsBlockData(true)
        }
      } else if (data.query === 'bq-visitors-journeys') {
        this.updateIsCollapsedVisitorsJourneysBlock(!this.isCollapsedVisitorsJourneysBlock)

        if (!this.isFetchedVisitorsJourneysBlockData) {
          this.updateIsFetchedVisitorsJourneysBlockData(true)
        }
      } else if (data.query === 'bq-communication-analytics') {
        this.updateIsCollapsedCommunicationAnalyticsBlock(!this.isCollapsedCommunicationAnalyticsBlock)

        if (!this.isFetchedCommunicationAnalyticsBlockData) {
          this.updateIsFetchedCommunicationAnalyticsBlockData(true)
        }
      }

      this.filterKey = Math.random().toString(36).substring(2, 15)
    })
  },

  async mounted() {
    if (this.activeUserInfo && this.activeUserInfo.superadmin) {
      this.selectedOption = this.selectedAccount

      await this.$vs.loading()
      const companies = []
      const companiesRef = await this.$db.collection('company').orderBy('created', 'desc').get()
      companiesRef.forEach((doc) => {
        const company = doc.data()
        const _company = {
          id: doc.id,
          name: company.name,
          vegaActivationDate: company.vegaActivationDate
        }
        companies.push(_company)
      })
      this.setAccountList(companies)
    }

    await this.$vs.loading()

    await this.getDialogsList()
    await this.getCampaignsList()

    this.fetchOpenedData()

    await this.$vs.loading.close()
  },

  beforeDestroy() {
    this.$serverBus.$off('fetch-data-in-blocks')

    if (!this.isCollapsedCampaignsBlock) {
      this.updateIsCollapsedCampaignsBlock(true)
    }

    if (!this.isCollapsedVisitorAnalyticsBlock) {
      this.updateIsCollapsedVisitorAnalyticsBlock(true)
    }

    if (!this.isCollapsedWebsiteAnalyticsBlock) {
      this.updateIsCollapsedWebsiteAnalyticsBlock(true)
    }

    if (!this.isCollapsedVisitorsJourneysBlock) {
      this.updateIsCollapsedVisitorsJourneysBlock(true)
    }

    if (!this.isCollapsedCommunicationAnalyticsBlock) {
      this.updateIsCollapsedCommunicationAnalyticsBlock(true)
    }
  },

  methods: {
    ...mapActions({
      updateLastUpdated: 'dashboard/updateLastUpdated',
      updateDateRange: 'dashboard/updateDateRange',
      updateDialogList: 'dashboard/updateDialogList',
      updateCampaignList: 'dashboard/updateCampaignList',
      updateDeviceData: 'dashboard/updateDeviceData',
      updateBrowserData: 'dashboard/updateBrowserData',
      updateAgentsData: 'dashboard/updateAgentsData',
      updateRouteClicks: 'dashboard/updateRouteClicks',
      updateTopReferers: 'dashboard/updateTopReferers',
      updateTopOrigins: 'dashboard/updateTopOrigins',
      updateTotalVisitors: 'dashboard/updateTotalVisitors',
      updateUniqueVisitors: 'dashboard/updateUniqueVisitors',
      updateReturningVisitors: 'dashboard/updateReturningVisitors',
      updateTopVisitors: 'dashboard/updateTopVisitors',
      updateContactFormShownCount: 'dashboard/updateContactFormShownCount',
      updateContactFormFilledCount: 'dashboard/updateContactFormFilledCount',
      updateContactFormBookedAppointmnetCount: 'dashboard/updateContactFormBookedAppointmnetCount',
      updateEndScreenFeedbackData: 'dashboard/updateEndScreenFeedbackData',
      updateVisitorsRatingData: 'dashboard/updateVisitorsRatingData',

      updateShowsCount: 'dashboard/updateShowsCount',
      updateRequestCount: 'dashboard/updateRequestCount',
      updateChatRequestCount: 'dashboard/updateChatRequestCount',
      updateAudioRequestCount: 'dashboard/updateAudioRequestCount',
      updateVideoRequestCount: 'dashboard/updateVideoRequestCount',

      updateSessionsCount: 'dashboard/updateSessionsCount',
      updateChatSessionCount: 'dashboard/updateChatSessionCount',
      updateAudioSessionCount: 'dashboard/updateAudioSessionCount',
      updateVideoSessionCount: 'dashboard/updateVideoSessionCount',
      updateSessionDuration: 'dashboard/updateSessionDuration',

      updateShowsData: 'dashboard/updateShowsData',
      updaterequestsData: 'dashboard/updaterequestsData',
      updateSessionsData: 'dashboard/updateSessionsData',

      /* Campaigns */
      updateCampaignShowsCount: 'dashboard/updateCampaignShowsCount',
      updateCampaignRequestCount: 'dashboard/updateCampaignRequestCount',
      updateCampaignChatRequestCount: 'dashboard/updateCampaignChatRequestCount',
      updateCampaignAudioRequestCount: 'dashboard/updateCampaignAudioRequestCount',
      updateCampaignVideoRequestCount: 'dashboard/updateCampaignVideoRequestCount',

      updateCampaignSessionsCount: 'dashboard/updateCampaignSessionsCount',
      updateCampaignChatSessionCount: 'dashboard/updateCampaignChatSessionCount',
      updateCampaignAudioSessionCount: 'dashboard/updateCampaignAudioSessionCount',
      updateCampaignVideoSessionCount: 'dashboard/updateCampaignVideoSessionCount',
      updateCampaignSessionDuration: 'dashboard/updateCampaignSessionDuration',

      updateCampaignShowsData: 'dashboard/updateCampaignShowsData',
      updateCampaignRequestsData: 'dashboard/updateCampaignRequestsData',
      updateCampaignSessionsData: 'dashboard/updateCampaignSessionsData',

      updateVegaAgentConversationRatingData: 'dashboard/updateVegaAgentConversationRatingData',
      updateVegaConversationStatusData: 'dashboard/updateVegaConversationStatusData',
      updateVegaVisitorAnalyticsData: 'dashboard/updateVegaVisitorAnalyticsData',
      updateVegaVisitorsCountPerDayData: 'dashboard/updateVegaVisitorsCountPerDayData',
      updateVegaFilledContactFormStatusData: 'dashboard/updateVegaFilledContactFormStatusData',
      updateVegaScoresAnalyticsData: 'dashboard/updateVegaScoresAnalyticsData',
      updateVegaCampaignsData: 'dashboard/updateVegaCampaignsData',
      updateVegaDataFetched: 'dashboard/updateVegaDataFetched',
      updateVegaVisitorScoreFlowData: 'dashboard/updateVegaVisitorScoreFlowData',
      updateIsFetchedVisitorsJourneysBlockData: 'dashboard/updateIsFetchedVisitorsJourneysBlockData',
      setSelectedAccount: 'setSelectedAccount',
      setAccountList: 'setAccountList',
      setSelectedAccountApiKeys: 'setSelectedAccountApiKeys',

      updateIsCollapsedCampaignsBlock: 'dashboard/updateIsCollapsedCampaignsBlock',
      updateIsCollapsedVisitorAnalyticsBlock: 'dashboard/updateIsCollapsedVisitorAnalyticsBlock',
      updateIsCollapsedWebsiteAnalyticsBlock: 'dashboard/updateIsCollapsedWebsiteAnalyticsBlock',
      updateIsCollapsedVisitorsJourneysBlock: 'dashboard/updateIsCollapsedVisitorsJourneysBlock',
      updateIsFetchedCampaignsBlockData: 'dashboard/updateIsFetchedCampaignsBlockData',
      updateIsFetchedVisitorAnalyticsBlockData: 'dashboard/updateIsFetchedVisitorAnalyticsBlockData',
      updateIsFetchedWebsiteAnalyticsBlockData: 'dashboard/updateIsFetchedWebsiteAnalyticsBlockData',
      updateIsFetchedVisitorsJourneysBlockData: 'dashboard/updateIsFetchedVisitorsJourneysBlockData',
      updateIsCollapsedCommunicationAnalyticsBlock: 'dashboard/updateIsCollapsedCommunicationAnalyticsBlock',
      updateIsFetchedCommunicationAnalyticsBlockData: 'dashboard/updateIsFetchedCommunicationAnalyticsBlockData'
    }),

    goToVegaActivation() {
      if (this.isMobile) {
        return this.$router.push({ name: 'vega' })
      }

      this.$router.push({ name: 'payment', params: { action: 'activate-vega' } })
    },

    refreshData() {
      this.updateIsCollapsedCampaignsBlock(true)
      this.updateIsCollapsedVisitorAnalyticsBlock(true)
      this.updateIsCollapsedWebsiteAnalyticsBlock(true)
      this.updateIsCollapsedVisitorsJourneysBlock(true)
      this.updateIsCollapsedCommunicationAnalyticsBlock(true)
      this.updateIsFetchedCampaignsBlockData(false)
      this.updateIsFetchedVisitorAnalyticsBlockData(false)
      this.updateIsFetchedWebsiteAnalyticsBlockData(false)
      this.updateIsFetchedVisitorsJourneysBlockData(false)
      this.updateIsFetchedCommunicationAnalyticsBlockData(false)

      this.fetchOpenedData(true)
      this.disableRefreshButton()
    },
    disableRefreshButton() {
      this.disableRefreshBtn = true

      setInterval(() => {
        this.disableRefreshBtn = false
      }, 600000)
    },
    handleDialogChange(value) {
      this.selectedDialogs = value
      this.selectedVegaDialogs = value.filter((dialog) => dialog.isVegaWidget)

      this.disableRefreshBtn = false
    },
    handleCampaignChange(value) {
      this.selectedCampaigns = value
      this.disableRefreshBtn = false
    },
    async handleAccountChange(account) {
      if (account.id) {
        const apiKeysDoc = await this.$db.collection('api-keys').doc(account.id).get()
        const apiKeys = apiKeysDoc.data()
        this.setSelectedAccountApiKeys(apiKeys)
        await this.getDialogsList(true)
        await this.getCampaignsList(true)
        await this.refreshData()
      }
    },
    handleDateRangeChange(value) {
      this.updateDateRange({
        endDate: dayjs(value.endDate).format('ddd MMM D YYYY HH:mm Z'),
        startDate: dayjs(value.startDate).format('ddd MMM D YYYY HH:mm Z')
      })

      this.fetchOpenedData(true)
    },
    async fetchGeneralData(forceFetch) {
      this.getRequestData(forceFetch)
      this.getSessionsData(forceFetch)
      this.getContactFormCount(forceFetch)
      this.getVisitorsRating(forceFetch)
      this.getAgentsData(forceFetch)
      this.getRouteClicks(forceFetch)

      setTimeout(() => {
        this.getSessionDuration(forceFetch)

        if (!this.isMobile) {
          this.getCampaignRequestData(forceFetch)
          this.getCampaignSessionsData(forceFetch)
          this.getEndScreenFeedback(forceFetch)
          this.getTopReferers(forceFetch)
          this.getTopOrigins(forceFetch)
          this.getDeviceData(forceFetch)
          this.getBrowsersData(forceFetch)
        }
      }, 500)

      this.getVisitorsLocations(forceFetch)

      if (forceFetch) {
        this.updateLastUpdated(dayjs().format())
      }

      if (this.getDiffInMinutes(dayjs().format(), this.lastUpdated) < 10) {
        this.disableRefreshButton()
      }
    },

    changeActiveView() {
      if (this.company && this.company.isDemoAccount) {
        return this.fetchDemoVegaData()
      }

      this.getVegaDataMethod('bq-visitors-count-per-day', 'updateVegaVisitorsCountPerDayData', 'isVegaVisitorsCountPerDayDataLoading')
    },

    async fetchDemoVegaData() {
      this.isVegaVisitorsCountPerDayDataLoading = true

      try {
        this.updateVegaDataFetched({ ...this.vegaDataFetched, vegaAnalytics: true })

        this.vegaFilterKey = Math.random().toString(36).substring(2, 15)

        const demoDataForSpecificView =
          this.activeChartViewRangeOnAnalyticsSummary === 'day' ? this.demoDataBqVisitorsCountPerDayDays : this.demoDataBqVisitorsCountPerDayWeek

        await this.updateVegaVisitorsCountPerDayData(demoDataForSpecificView)
        this.getVegaVisitorsCountPerDay()

        // TODO Remove comment on Dec, 2024
        //this.getTotalVisitorsCount(true)
        //this.getShowDataPerDayOfWeek(true)

        this.updateLastUpdated(dayjs().format())
      } catch (err) {
        console.log('Error: ', err)
      }

      this.isVegaVisitorsCountPerDayDataLoading = false
    },

    async fetchOpenedData(forceFetch) {
      if (this.company && this.company.isDemoAccount) {
        return this.fetchDemoVegaData()
      }

      if (
        this.selectedAccountApiKeys &&
        this.selectedAccountApiKeys.vega &&
        this.selectedAccountId &&
        ((this.company && this.company.isVegaAccount && this.isVegaActivated) || this.activeUserInfo.superadmin)
      ) {
        this.filterKey = Math.random().toString(36).substring(2, 15)
        this.updateVegaDataFetched({ ...this.vegaDataFetched, vegaAnalytics: true })

        this.getVegaDataMethod('bq-visitors-count-per-day', 'updateVegaVisitorsCountPerDayData', 'isVegaVisitorsCountPerDayDataLoading')
        // TODO Remove comment on Dec, 2024
        // this.getTotalVisitorsCount(true)
        // this.getShowDataPerDayOfWeek(true)

        if (!this.isCollapsedVisitorAnalyticsBlock) {
          this.getVegaDataMethod('bq-visitor-analytics', 'updateVegaVisitorAnalyticsData', 'isVegaVisitorAnalyticsDataLoading')
        }

        if (!this.isCollapsedWebsiteAnalyticsBlock) {
          this.getVegaDataMethod('bq-scores-analytics', 'updateVegaScoresAnalyticsData', 'isVegaScoresAnalyticsDataLoading')
        }

        if (!this.isCollapsedCampaignsBlock) {
          this.getVegaDataMethod('bq-campaign-analytics', 'updateVegaCampaignsData', 'isVegaCampaignsDataLoading')
        }

        if (!this.isCollapsedCommunicationAnalyticsBlock) {
          this.getVegaDataMethod('bq-visitor-contacts', 'updateVegaFilledContactFormStatusData', 'isVegaFilledContactFormStatusDataLoading')
          this.getVegaDataMethod('bq-conversation-status', 'updateVegaConversationStatusData', 'isVegaConversationStatusDataLoading')
          this.getVegaDataMethod('bq-conversation-rating', 'updateVegaAgentConversationRatingData', 'isVegaAgentConversationRatingDataLoading')

          setTimeout(() => {
            this.fetchGeneralData(forceFetch)
          }, 300)
        }
      } else if (this.company) {
        this.filterKey = Math.random().toString(36).substring(2, 15)

        // TODO Remove comment on Dec, 2024
        // this.getTotalVisitorsCount(true)
        // this.getShowDataPerDayOfWeek(true)

        if (!this.isCollapsedCommunicationAnalyticsBlock) {
          this.fetchGeneralData(true)
        }
      }

      if (forceFetch) {
        this.updateLastUpdated(dayjs().format())
      }

      if (this.getDiffInMinutes(dayjs().format(), this.lastUpdated) < 10) {
        this.disableRefreshButton()
      }
    },

    getDiffInMinutes(date1, date2) {
      const d1 = dayjs(date1)
      const d2 = dayjs(date2)
      const diff = d1.diff(d2, 'minute')

      return diff
    },

    async getVegaDataMethod(endpoint, actionName, isLoadingField, value) {
      this[isLoadingField] = true

      try {
        const selectedVegaDialogsIds = this.selectedVegaDialogs.map((item) => item.dialogId)

        const payload = {
          companyId: this.selectedAccountId,
          start: this.startOfDay,
          end: this.endOfDay,
          widgets: selectedVegaDialogsIds
        }

        if (endpoint === 'bq-visitor-score-flow-page') {
          payload.url = value
          payload.maxPages = 3
          payload.limit = 500
        }

        if (['bq-visitor-score-flow-page', 'bq-visitors-count-per-day'].includes(endpoint)) {
          payload.threshold = this.company.vegaPopupThreshold || 'hot'
        }

        if (endpoint === 'bq-visitors-count-per-day') {
          payload.groupByPeriod = this.activeChartViewRangeOnAnalyticsSummary
        }

        const response = await this.callHttpRequestVega(endpoint, payload, this.selectedAccountApiKeys.vega, this.$vegaEndPoint)

        this[actionName](response)

        if (endpoint === 'bq-visitors-count-per-day') {
          this.getVegaVisitorsCountPerDay()
        }
      } catch (err) {
        console.log('Error: ', err)
      }

      this[isLoadingField] = false
    },

    async getTotalVisitorsCount(forceFetch) {
      this.isTotalVisitorsCountLoading = true

      if (this.totalVisitors && !forceFetch) {
        this.isTotalVisitorsCountLoading = false
        return
      }

      const config = {
        queryFilter: ` dt.company_id="${this.selectedAccountId}"`
      }
      // TODO Remove comment on Dec, 2024
      //config.queryFilter += generateDialogFilter(this.selectedDialogs, true)
      config.queryFilter += ` AND dt.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`

      this.updateTotalVisitors(0)
      this.updateUniqueVisitors(0)
      this.updateReturningVisitors(0)

      try {
        let data = null

        if (this.company && this.company.isDemoAccount) {
          data = this.demoDataGetVisitorsCountDataBQ
        } else {
          const getVisitorsCountDataBQ = this.$functions.httpsCallable('getVisitorsCountDataBQ')
          const rs = await getVisitorsCountDataBQ({ config })

          data = rs.data.res
        }

        let uniqueVisitors = 0
        let returningVisitors = 0
        if (data.find((x) => x.type === 'unique')) {
          uniqueVisitors = data.find((x) => x.type === 'unique').total
        }
        if (data.find((x) => x.type === 'returning')) {
          returningVisitors = data.find((x) => x.type === 'returning').total
        }
        const totalVisitors = uniqueVisitors + returningVisitors
        this.updateUniqueVisitors(uniqueVisitors)
        this.updateReturningVisitors(returningVisitors)
        this.updateTotalVisitors(totalVisitors)
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }
      this.isTotalVisitorsCountLoading = false
    },
    async getShowData(forceFetch) {
      this.isDialogShowsLoading = true

      if (this.showsCount && !forceFetch) {
        this.isDialogShowsLoading = false
        this.getShowCountPerDay()
        this.getShowCountPerWeek()
        return
      }

      const config = {
        queryFilter: ` company_id="${this.selectedAccountId}" `
      }

      //config.queryFilter += generateDialogFilter(this.selectedDialogs, false)
      config.queryFilter += ` AND updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`

      try {
        let data = null

        if (this.company && this.company.isDemoAccount) {
          data = this.demoDataGetShowCountDataBQ
        } else {
          const getShowCountDataBQ = this.$functions.httpsCallable('getShowCountDataBQ')
          const rs = await getShowCountDataBQ({ config })

          data = rs.data.res
        }

        if (data) {
          this.updateShowsCount(data.length)
          this.updateShowsData(data)
        }

        this.getShowCountPerDay()
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isDialogShowsLoading = false
    },

    async getShowDataPerDayOfWeek(forceFetch) {
      this.isDialogShowsPerDayOfWeekLoading = true

      if (this.showsCount && !forceFetch) {
        this.isDialogShowsPerDayOfWeekLoading = false
        this.getShowCountPerWeek()
        return
      }

      const config = {
        queryFilter: ` company_id="${this.selectedAccountId}" `
      }

      // TODO Remove comment on Dec, 2024
      // config.queryFilter += generateDialogFilter(this.selectedDialogs, false)
      config.queryFilter += ` AND updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`

      try {
        let data = null

        if (this.company && this.company.isDemoAccount) {
          data = this.demoDataGetShowCountDataBQ
        } else {
          const getShowCountDataBQ = this.$functions.httpsCallable('getShowCountDataBQ')
          const rs = await getShowCountDataBQ({ config })

          data = rs.data.res
        }

        if (data) {
          this.updateShowsData(data)
        }

        this.getShowCountPerWeek()
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isDialogShowsPerDayOfWeekLoading = false
    },
    updateRequestTypeCount(requests) {
      if (requests && requests.length > 0) {
        const chatRequests = requests.filter((x) => x.request_type === 'chat')
        const audioRequests = requests.filter((x) => x.request_type === 'audio')
        const videoRequests = requests.filter((x) => x.request_type === 'video')
        if (chatRequests && chatRequests.length > 0) {
          this.updateChatRequestCount(chatRequests.length)
        } else {
          this.updateChatRequestCount(0)
        }
        if (audioRequests && audioRequests.length > 0) {
          this.updateAudioRequestCount(audioRequests.length)
        } else {
          this.updateAudioRequestCount(0)
        }
        if (videoRequests && videoRequests.length > 0) {
          this.updateVideoRequestCount(videoRequests.length)
        } else {
          this.updateVideoRequestCount(0)
        }
      } else {
        this.updateChatRequestCount(0)
        this.updateAudioRequestCount(0)
        this.updateVideoRequestCount(0)
      }
    },
    updateCampaignRequestTypeCount(requests) {
      if (requests && requests.length > 0) {
        const chatRequests = requests.filter((x) => x.request_type === 'chat')
        const audioRequests = requests.filter((x) => x.request_type === 'audio')
        const videoRequests = requests.filter((x) => x.request_type === 'video')
        if (chatRequests && chatRequests.length > 0) {
          this.updateCampaignChatRequestCount(chatRequests.length)
        } else {
          this.updateCampaignChatRequestCount(0)
        }
        if (audioRequests && audioRequests.length > 0) {
          this.updateCampaignAudioRequestCount(audioRequests.length)
        } else {
          this.updateCampaignAudioRequestCount(0)
        }
        if (videoRequests && videoRequests.length > 0) {
          this.updateCampaignVideoRequestCount(videoRequests.length)
        } else {
          this.updateCampaignVideoRequestCount(0)
        }
      } else {
        this.updateCampaignChatRequestCount(0)
        this.updateCampaignAudioRequestCount(0)
        this.updateCampaignVideoRequestCount(0)
      }
    },
    updateSessionTypeCount(sessions) {
      if (sessions && sessions.length > 0) {
        const chatSessions = sessions.filter((x) => x.request_type === 'chat')
        const audioSessions = sessions.filter((x) => x.request_type === 'audio')
        const videoSessions = sessions.filter((x) => x.request_type === 'video')
        if (chatSessions && chatSessions.length > 0) {
          this.updateChatSessionCount(chatSessions.length)
        } else {
          this.updateChatSessionCount(0)
        }
        if (audioSessions && audioSessions.length > 0) {
          this.updateAudioSessionCount(audioSessions.length)
        } else {
          this.updateAudioSessionCount(0)
        }
        if (videoSessions && videoSessions.length > 0) {
          this.updateVideoSessionCount(videoSessions.length)
        } else {
          this.updateVideoSessionCount(0)
        }
      } else {
        this.updateChatSessionCount(0)
        this.updateAudioSessionCount(0)
        this.updateVideoSessionCount(0)
      }
    },

    updateCampaignSessionTypeCount(sessions) {
      if (sessions && sessions.length > 0) {
        const chatSessions = sessions.filter((x) => x.request_type === 'chat')
        const audioSessions = sessions.filter((x) => x.request_type === 'audio')
        const videoSessions = sessions.filter((x) => x.request_type === 'video')
        if (chatSessions && chatSessions.length > 0) {
          this.updateCampaignChatSessionCount(chatSessions.length)
        } else {
          this.updateCampaignChatSessionCount(0)
        }
        if (audioSessions && audioSessions.length > 0) {
          this.updateCampaignAudioSessionCount(audioSessions.length)
        } else {
          this.updateCampaignAudioSessionCount(0)
        }
        if (videoSessions && videoSessions.length > 0) {
          this.updateCampaignVideoSessionCount(videoSessions.length)
        } else {
          this.updateCampaignVideoSessionCount(0)
        }
      } else {
        this.updateCampaignChatSessionCount(0)
        this.updateCampaignAudioSessionCount(0)
        this.updateCampaignVideoSessionCount(0)
      }
    },

    async getRequestData(forceFetch) {
      this.isRequestsLoading = true

      if (this.requestCount && !forceFetch) {
        this.isRequestsLoading = false
        //this.getCustomerRequestCountPerDay()
        //this.getCustomerRequestCountPerWeek()
        return
      }

      const config = {
        queryFilter: ` appearance='automatic' AND company_id="${this.selectedAccountId}"`
      }

      config.queryFilter += ` AND updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`
      config.queryFilter += generateDialogFilter(this.selectedDialogs, false)

      try {
        let data = null

        if (this.company && this.company.isDemoAccount) {
          data = this.demoDataGetRequestCountDataBQ
        } else {
          const getRequestCountDataBQ = this.$functions.httpsCallable('getRequestCountDataBQ')
          const rs = await getRequestCountDataBQ({ config })
          data = rs.data.res
        }

        if (data) {
          //this.updateRequestCount(data.length)
          this.updaterequestsData(data)
          //this.updateRequestTypeCount(data)
        }

        //this.getCustomerRequestCountPerDay()
        //this.getCustomerRequestCountPerWeek()
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isRequestsLoading = false
    },

    async getCampaignRequestData(forceFetch) {
      this.isCampaignRequestsLoading = true

      if (this.campaignRequestCount && !forceFetch) {
        this.isCampaignRequestsLoading = false
        return
      }

      const config = {
        queryFilter: ` appearance='automatic' AND company_id="${this.selectedAccountId}"`
      }

      config.queryFilter += ` AND updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}" `

      config.queryFilter += generateCampaignFilter(this.selectedCampaigns, false)

      try {
        let data = null

        if (this.company && this.company.isDemoAccount) {
          data = this.demoDataGetRequestCountDataBQ
        } else {
          const getRequestCountDataBQ = this.$functions.httpsCallable('getRequestCountDataBQ')
          const rs = await getRequestCountDataBQ({ config })

          data = rs.data.res
        }

        if (data) {
          //this.updateCampaignRequestCount(data.length)
          this.updateCampaignRequestsData(data)
          //this.updateCampaignRequestTypeCount(data)
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isCampaignRequestsLoading = false
    },

    async getSessionsData(forceFetch) {
      this.isSessionsLoading = true

      if (this.sessionsCount && !forceFetch) {
        this.isSessionsLoading = false
        this.getSessionsCountPerDay()
        this.getSessionsCountPerWeek()
        return
      }

      const config = {
        queryFilter: ' '
      }

      config.queryFilter += `  dt.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}" `
      config.queryFilter += generateDialogFilter(this.selectedDialogs, true)

      try {
        let data = null

        if (this.company && this.company.isDemoAccount) {
          data = this.demoDataGetSessionsCountDataBQ
        } else {
          const getSessionsCountDataBQ = this.$functions.httpsCallable('getSessionsCountDataBQ')
          const rs = await getSessionsCountDataBQ({ config })

          data = rs.data.res
        }

        if (data) {
          this.updateSessionsData(data)
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isSessionsLoading = false
    },

    async getCampaignSessionsData(forceFetch) {
      this.isCampaignSessionsLoading = true

      if (this.campaignSessionsCount && !forceFetch) {
        this.isCampaignSessionsLoading = false
        return
      }

      const config = {
        queryFilter: ''
      }

      config.queryFilter += `  dt.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}" `
      config.queryFilter += generateCampaignFilter(this.selectedCampaigns, true)

      try {
        let data = null

        if (this.company && this.company.isDemoAccount) {
          data = this.demoDataGetCampaignSessionsCountDataBQ
        } else {
          const getCampaignSessionsCountDataBQ = this.$functions.httpsCallable('getCampaignSessionsCountDataBQ')
          const rs = await getCampaignSessionsCountDataBQ({ config })

          data = rs.data.res
        }

        if (data) {
          this.updateCampaignSessionsCount(data.length)
          this.updateCampaignSessionsData(data)
          this.updateCampaignSessionTypeCount(data)
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isCampaignSessionsLoading = false
    },

    async getSessionDuration(forceFetch) {
      this.isSessionDurationLoading = false

      if (this.sessionDuration !== '00:00:00' && !forceFetch) {
        this.isSessionDurationLoading = false
        return
      }

      const config = {
        queryFilter: ` dt.company_id = "${this.selectedAccountId}" `
      }

      config.queryFilter += ` AND dt.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`
      config.queryFilter += generateDialogFilter(this.selectedDialogs, true)

      try {
        let data = null

        if (this.company && this.company.isDemoAccount) {
          data = this.demoDataGetSessionDurationDataBQ
        } else {
          const getSessionDurationDataBQ = this.$functions.httpsCallable('getSessionDurationDataBQ')
          const rs = await getSessionDurationDataBQ({ config })

          data = rs.data.res
        }

        if (data && data[0]) {
          this.updateSessionDuration(new Date(data[0].average_duration_in_seconds * 1000).toISOString().substr(11, 8))
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isSessionDurationLoading = false
    },
    async getContactFormCount(forceFetch) {
      this.isContactFormLoading = true

      if (this.contactFormShownCount && !forceFetch) {
        this.isContactFormLoading = false
        return
      }

      const config = {
        queryFilter: ` company_id="${this.selectedAccountId}"`
      }

      config.queryFilter += generateDialogCampaignFilter(this.selectedDialogs, this.selectedCampaigns, false)
      config.queryFilter += ` AND updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`

      this.updateContactFormShownCount(0)
      this.updateContactFormFilledCount(0)
      this.updateContactFormBookedAppointmnetCount(0)

      try {
        let result = null

        if (this.company && this.company.isDemoAccount) {
          result = this.demoDataGetContactFormCountDataBQ
        } else {
          const getContactFormCountDataBQ = this.$functions.httpsCallable('getContactFormCountDataBQ')
          const rs = await getContactFormCountDataBQ({ config })

          result = rs.data.res
        }

        if (result) {
          const display = result.find((x) => x.type === 'display')
          const filled = result.find((x) => x.type === 'form-saved')
          const booked = result.find((x) => x.type === 'booked-appointment')

          if (display) {
            this.updateContactFormShownCount(display.total)
          }
          if (filled) {
            this.updateContactFormFilledCount(filled.total)
          }
          if (booked) {
            this.updateContactFormBookedAppointmnetCount(booked.total)
          }
        }
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isContactFormLoading = false
    },
    async getVisitorsRating(forceFetch) {
      this.isVisitorsRatingLoading = true

      if (this.contactFormShownCount && !forceFetch) {
        this.isVisitorsRatingLoading = false
        return
      }

      const config = {
        queryFilter: ` dt.company="${this.selectedAccountId}"`
      }

      config.queryFilter += generateDialogFilter(this.selectedDialogs, this.selectedCampaigns, false)
      config.queryFilter += ` AND dt.created BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`
      config.queryFilter = config.queryFilter.replace('dialog_id', 'dialogId')

      const _visitorsRating = {
        totalCount: 0,
        goodCount: 0,
        neutralCount: 0,
        wasteOfTimeCount: 0
      }

      this.updateVisitorsRatingData(_visitorsRating)

      let result = null

      if (this.company && this.company.isDemoAccount) {
        result = this.demoDataGetvisitorsrating
      } else {
        result = await this.callHttpRequestGen2('getvisitorsrating', config, this.gcpFunctionToken, this.$gen2EndPoint)
      }

      if (result) {
        const _visitorsRating = {
          totalCount: result.total,
          goodCount: result.good,
          neutralCount: result.neutral,
          wasteOfTimeCount: result.bad,
          supportCount: result.support
        }
        this.updateVisitorsRatingData(_visitorsRating)
      }

      this.isVisitorsRatingLoading = false
    },
    async getDeviceData(forceFetch) {
      this.isDeviceLoading = true

      if (this.deviceData.length && !forceFetch) {
        this.isDeviceLoading = false
        return
      }

      const config = {
        company_id: this.selectedAccountId,
        queryFilter: ` v.company_id="${this.selectedAccountIdy}"`
      }

      config.queryFilter += generateDialogFilter(this.selectedDialogs, true)
      config.queryFilter += ` AND dt.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`

      try {
        let devices = null

        if (this.company && this.company.isDemoAccount) {
          devices = this.demoDataGetDeviceDataBQ
        } else {
          const getDeviceDataBQ = this.$functions.httpsCallable('getDeviceDataBQ')
          const rs = await getDeviceDataBQ({ config })

          devices = rs.data.res
        }

        const deviceData = []

        this.updateDeviceData([])

        devices.forEach((el) => {
          if (el.device && el.count) {
            deviceData.push({
              name: el.device,
              value: el.count
            })
          }
        })

        this.updateDeviceData(deviceData)
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isDeviceLoading = false
    },
    async getBrowsersData(forceFetch) {
      this.isBrowserDataLoading = true

      if (this.browserData.length && !forceFetch) {
        this.isBrowserDataLoading = false
        return
      }

      const config = {
        queryFilter: `st.connected_agent IS NOT NULL
            AND st.connected_agent_id IS NOT NULL
            AND st.duration IS NOT NULL
            AND dt.status = 'accepted' AND dt.session = true
            AND v.company_id = '${this.selectedAccountId}'`
      }

      config.queryFilter += ` AND dt.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`
      config.queryFilter += generateDialogFilter(this.selectedDialogs, true)

      try {
        let browsers = null

        if (this.company && this.company.isDemoAccount) {
          browsers = this.demoDataGetBrowsersDataBQ
        } else {
          const getBrowsersDataBQ = this.$functions.httpsCallable('getBrowsersDataBQ')
          const rs = await getBrowsersDataBQ({ config })

          browsers = rs.data.res
        }

        const browserData = []

        this.updateBrowserData([])

        browsers.forEach((el) => {
          if (el.browser && el.count) {
            browserData.push({
              name: el.browser,
              value: el.count
            })
          }
        })

        this.updateBrowserData(browserData)
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isBrowserDataLoading = false
    },
    async getVisitorsLocations(forceFetch) {
      this.isVisitorsLocationLoading = true

      if (this.topVisitors.length && !forceFetch) {
        this.isVisitorsLocationLoading = false
        return
      }

      function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }

      const config = {
        queryFilter: ` v.company_id="${this.selectedAccountId}"`
      }

      config.queryFilter += generateDialogFilter(this.selectedDialogs, true)
      config.queryFilter += ` AND v.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`
      config.queryFilter += ' GROUP BY city, country ORDER BY count desc LIMIT 10'

      try {
        let visitors = null

        if (this.company && this.company.isDemoAccount) {
          visitors = this.demoDataGetVisitorsLocationsDataBQ
        } else {
          const getVisitorsLocationsDataBQ = this.$functions.httpsCallable('getVisitorsLocationsDataBQ')
          const rs = await getVisitorsLocationsDataBQ({ config })

          visitors = rs.data.res
        }

        const topVisitors = []

        this.updateTopVisitors([])

        visitors.forEach((el) => {
          if (el.city && el.country && el.count) {
            topVisitors.push({
              city: `${capitalizeFirstLetter(el.city)} (${el.country})`,
              visits: el.count
            })
          }
        })

        this.updateTopVisitors(topVisitors)
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isVisitorsLocationLoading = false
    },
    async getDialogsList(forceFetch) {
      if (this.dialogList.length && !forceFetch) {
        this.selectedDialogs = this.widgetList
        this.selectedVegaDialogs = this.widgetList.filter((dialog) => dialog.isVegaWidget)
        return
      }

      this.updateDialogList([])

      const dialogList = []
      const res = await this.$db.collection('dialogs').where('company', '==', this.selectedAccountId).get()
      res.docs.forEach((doc) => {
        const dialog = doc.data()
        dialog.id = doc.id

        dialogList.push({
          dialogId: dialog.id,
          name: dialog.name,
          isVegaWidget: dialog.isVegaWidget
        })
      })

      this.updateDialogList(dialogList)

      if (dialogList.length) {
        this.selectedDialogs = this.widgetList
        this.selectedVegaDialogs = this.widgetList.filter((dialog) => dialog.isVegaWidget)
      }
    },
    async getCampaignsList(forceFetch) {
      if (this.campaignList.length && !forceFetch) {
        this.selectedCampaigns = this.campaignList
        return
      }

      this.updateCampaignList([])

      if (!this.selectedAccountId) {
        return
      }

      const campaignList = []
      const res = await this.$db.collection('campaigns').where('company', '==', this.selectedAccountId).get()
      res.docs.forEach((doc) => {
        const campaign = doc.data()
        campaign.id = doc.id

        if ((this.activeUserInfo.isAgent && campaign.createdBy === this.activeUserInfo.uid) || !this.activeUserInfo.isAgent) {
          campaignList.push({
            campaignId: campaign.id,
            name: campaign.name
          })
        }
      })

      this.updateCampaignList(campaignList)

      if (campaignList.length) {
        this.selectedCampaigns = campaignList
      }
    },

    async getAgentsData(forceFetch) {
      this.isAgentsLoading = true

      if (this.agentsData.length && !forceFetch) {
        this.isAgentsLoading = false
        return
      }

      const config = {
        queryFilter: ` dt.company_id = "${this.selectedAccountId}" AND dt.appearance = 'automatic' AND dt.status = 'accepted' AND dt.session = true
        AND st.connected_agent_id IS NOT NULL AND st.connected_agent IS NOT NULL AND st.duration IS NOT NULL`
      }

      config.queryFilter += ` AND dt.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`
      config.queryFilter += generateDialogFilter(this.selectedDialogs, true)
      config.queryFilter += ' GROUP BY na.firstname, na.lastname'

      try {
        let agents = null

        if (this.company && this.company.isDemoAccount) {
          agents = this.demoDataGetAgentsDataBQ
        } else {
          const getAgentsDataBQ = this.$functions.httpsCallable('getAgentsDataBQ')
          const rs = await getAgentsDataBQ({ config })

          agents = rs.data.res
        }

        const agentsData = []

        this.updateAgentsData([])

        agents.forEach((el) => {
          if (el.connected_agent && el.count) {
            agentsData.push({
              name: el.connected_agent,
              sessions: el.count
            })
          }
        })

        this.updateAgentsData(agentsData)
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isAgentsLoading = false
    },

    async getRouteClicks(forceFetch) {
      this.isRouteClicksLoading = true

      if (this.routeClicks.length && !forceFetch) {
        this.isRouteClicksLoading = false
        return
      }

      const config = {
        queryFilter: ` dt.companyId = "${this.selectedAccountId}" `
      }

      config.queryFilter += ` AND dt.created BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`
      config.queryFilter += generateDialogFilter(this.selectedDialogs, true)
      config.queryFilter = config.queryFilter.replace('dialog_id', 'dialogId')
      config.queryFilter += ' GROUP BY dt.userGroupName'

      try {
        let clicks = null

        if (this.company && this.company.isDemoAccount) {
          clicks = this.demoDataGetRouteClicksBQ
        } else {
          const getRouteClicksBQ = this.$functions.httpsCallable('getRouteClicksBQ')
          const rs = await getRouteClicksBQ({ config })

          clicks = rs.data.res
        }

        const routeClicks = []

        this.updateRouteClicks([])

        clicks.forEach((el) => {
          routeClicks.push({
            name: el.userGroupName,
            clicks: el.clicks
          })
        })

        this.updateRouteClicks(routeClicks)
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isRouteClicksLoading = false
    },

    async getTopReferers(forceFetch) {
      this.isRefererDataLoading = true

      if (this.topReferers.length && !forceFetch) {
        this.isRefererDataLoading = false
        return
      }

      const config = {
        queryFilter: ` v.company_id="${this.selectedAccountId}" AND dt.appearance = 'automatic' AND referer IS NOT NULL`
      }

      config.queryFilter += generateDialogFilter(this.selectedDialogs, true)
      config.queryFilter += ` AND v.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`
      config.queryFilter += ' GROUP BY referer ORDER BY count desc LIMIT 10'

      try {
        let referers = null

        if (this.company && this.company.isDemoAccount) {
          referers = this.demoDataGetTopReferersDataBQ
        } else {
          const getTopReferersDataBQ = this.$functions.httpsCallable('getTopReferersDataBQ')
          const rs = await getTopReferersDataBQ({ config })

          referers = rs.data.res
        }

        const topReferers = []

        this.updateTopReferers([])

        referers.forEach((el) => {
          if (el.referer && el.count) {
            topReferers.push({
              name: el.referer,
              value: el.count
            })
          }
        })

        this.updateTopReferers(topReferers)
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isRefererDataLoading = false
    },
    async getTopOrigins(forceFetch) {
      this.isOriginDataLoading = true

      if (this.topOrigins.length & !forceFetch) {
        this.isOriginDataLoading = false
        return
      }

      const config = {
        queryFilter: ` dt.company_id="${this.selectedAccountId}" AND dt.appearance = 'automatic' AND origin IS NOT NULL`
      }

      config.queryFilter += generateDialogFilter(this.selectedDialogs, true)
      config.queryFilter += ` AND v.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`
      config.queryFilter += ' GROUP BY origin ORDER BY count desc LIMIT 10'

      try {
        let origins = null

        if (this.company && this.company.isDemoAccount) {
          origins = this.demoDataGetTopOriginsDataBQ
        } else {
          const getTopOriginsDataBQ = this.$functions.httpsCallable('getTopOriginsDataBQ')
          const rs = await getTopOriginsDataBQ({ config })

          origins = rs.data.res
        }

        const topOrigins = []

        this.updateTopOrigins([])

        origins.forEach((el) => {
          if (el.origin && el.count) {
            topOrigins.push({
              name: el.origin,
              value: el.count
            })
          }
        })

        this.updateTopOrigins(topOrigins)
      } catch (err) {
        // eslint-disable-next-line
        console.error(err.message)
      }

      this.isOriginDataLoading = false
    },
    async getEndScreenFeedback(forceFetch) {
      this.isEndScreenFeedbackDataLoading = true

      if (this.endScreenFeedbackData && this.endScreenFeedbackData.length && !forceFetch) {
        this.isEndScreenFeedbackDataLoading = false
        return
      }

      const config = {
        fields:
          'updated, question_first, grade_first, question_second, grade_second, question_third, grade_third, feedback_message, agent_id, agent_name, agent_image, feedback_type, dialog_name, campaign_id, grade_average',
        queryFilter: ` dt.company_id="${this.selectedAccountId}"`
      }

      config.queryFilter += generateDialogCampaignFilter(this.selectedDialogs, this.selectedCampaigns, false)
      config.queryFilter += ` AND dt.updated BETWEEN "${this.startOfDay}" AND "${this.endOfDay}"`

      try {
        let feedbackResData = null

        if (this.company && this.company.isDemoAccount) {
          feedbackResData = this.demoDataGetEndScreenFeedbackDataBQ
        } else {
          const getEndScreenFeedbackDataBQ = this.$functions.httpsCallable('getEndScreenFeedbackDataBQ')
          const rs = await getEndScreenFeedbackDataBQ({ config })

          feedbackResData = rs.data.res
        }

        const feedbackData = []

        this.updateEndScreenFeedbackData([])

        feedbackResData.forEach((el) => {
          feedbackData.push(el)
        })

        this.updateEndScreenFeedbackData(feedbackData)
      } catch (err) {
        /* eslint-disable no-console */
        console.error(err.message)
      }

      this.isEndScreenFeedbackDataLoading = false
    },
    getShowCountPerDay() {
      this.showCountPerHour[0].data = new Array(24).fill(0)

      for (const el of this.showsData) {
        const date = el.updated.value
        const hour = dayjs(date).hour()

        this.showCountPerHour[0].data[hour] = this.showCountPerHour[0].data[hour] + 1
      }
    },
    getShowCountPerWeek() {
      this.showCountPerDay[0].data = new Array(7).fill(0)

      for (const el of this.showsData) {
        const date = el.updated.value
        const day = dayjs(date).day()

        this.showCountPerDay[0].data[day] = this.showCountPerDay[0].data[day] + 1
      }
    },
    getCustomerRequestCountPerDay() {
      this.customerReqeuestsCountPerHour[0].data = new Array(24).fill(0)

      for (const el of this.requestsData) {
        const date = el.updated.value
        const hour = dayjs(date).hour()

        this.customerReqeuestsCountPerHour[0].data[hour] = this.customerReqeuestsCountPerHour[0].data[hour] + 1
      }
    },
    getCustomerRequestCountPerWeek() {
      this.customerReqeuestsCountPerDay[0].data = new Array(7).fill(0)

      for (const el of this.requestsData) {
        const date = el.updated.value
        const day = dayjs(date).day()

        this.customerReqeuestsCountPerDay[0].data[day] = this.customerReqeuestsCountPerDay[0].data[day] + 1
      }
    },
    getVegaVisitorsCountPerDay() {
      const isDayView = this.activeChartViewRangeOnAnalyticsSummary === 'day'

      this.vegaVisitorsCountPerDayChart[0].totalData = isDayView ? [] : new Array(7).fill(0)
      this.vegaVisitorsCountPerDayChart[0].thresholdData = isDayView ? [] : new Array(7).fill(0)

      if (!this.vegaVisitorsCountPerDay) {
        return
      }

      if (this.vegaVisitorsCountPerDay.overallResult) {
        if (isDayView) {
          this.vegaVisitorsCountPerDayChart[0].totalData = this.vegaVisitorsCountPerDay.overallResult
        } else {
          this.vegaVisitorsCountPerDay.overallResult.forEach((item) => {
            this.vegaVisitorsCountPerDayChart[0].totalData[item.day_of_week - 1] = item.unique_visitor_count
          })
        }
      }

      if (this.vegaVisitorsCountPerDay.thresholdResult) {
        if (isDayView) {
          this.vegaVisitorsCountPerDayChart[0].thresholdData = this.vegaVisitorsCountPerDay.thresholdResult
        } else {
          this.vegaVisitorsCountPerDay.thresholdResult.forEach((item) => {
            this.vegaVisitorsCountPerDayChart[0].thresholdData[item.day_of_week - 1] = item.unique_visitor_count
          })
        }
      }
    },
    getSessionsCountPerDay() {
      this.sessionsCountPerHour[0].data = new Array(24).fill(0)

      for (const el of this.sessionsData) {
        const date = el.updated.value
        const hour = dayjs(date).hour()

        this.sessionsCountPerHour[0].data[hour] = this.sessionsCountPerHour[0].data[hour] + 1
      }
    },
    getSessionsCountPerWeek() {
      this.sessionsCountPerDay[0].data = new Array(7).fill(0)

      for (const el of this.sessionsData) {
        const date = el.updated.value
        const day = dayjs(date).day()

        this.sessionsCountPerDay[0].data[day] = this.sessionsCountPerDay[0].data[day] + 1
      }
    },
    capitalizeFirstLetter(s) {
      if (typeof s !== 'string') return ''
      return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
    },
    daysOfWeek() {
      return [
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.sun')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.mon')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.tue')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.wed')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.thu')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.fri')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.short.sat'))
      ]
    },
    daysOfWeekLong() {
      return [
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.sun')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.mon')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.tue')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.wed')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.thu')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.fri')),
        this.capitalizeFirstLetter(this.$i18n.t('system.weekdays.long.sat'))
      ]
    },
    monthNames() {
      return [
        this.$i18n.t('system.months.short.jan'),
        this.$i18n.t('system.months.short.feb'),
        this.$i18n.t('system.months.short.mar'),
        this.$i18n.t('system.months.short.apr'),
        this.$i18n.t('system.months.short.may'),
        this.$i18n.t('system.months.short.jun'),
        this.$i18n.t('system.months.short.jul'),
        this.$i18n.t('system.months.short.aug'),
        this.$i18n.t('system.months.short.sep'),
        this.$i18n.t('system.months.short.oct'),
        this.$i18n.t('system.months.short.nov'),
        this.$i18n.t('system.months.short.dec')
      ]
    }
  }
}
</script>

<style lang="scss">
.vega-analytics-container {
  .margin-rt,
  .margin-lt {
    display: flex;

    .chart-info {
      .vx-card {
        height: 100%;
      }
    }
  }

  .vs-list--header,
  .vs-list--title {
    margin-bottom: 0 !important;
    padding: 0 !important;
    color: rgb(38, 38, 41) !important;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
  }

  .vs-list--header {
    padding-bottom: 5px !important;
  }

  .chart-info__subtitle {
    font-size: 13px;
    line-height: 16px;
    color: rgb(194, 192, 197) !important;
  }

  .summary-info {
    .vs-list--title {
      color: rgb(255, 255, 255) !important;
    }

    .chart-info__subtitle {
      color: rgb(255, 255, 255) !important;
    }
  }

  .con-vs-tooltip {
    padding-top: 2px;
  }
}
</style>

<style lang="scss">
.activate-vega {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 700px;
  max-width: calc(100% - 50px);
  padding: 14px 24px;
  font-size: 18px;
  font-family: 'Larsseit';
  font-style: normal;
  font-weight: 700;
  color: rgb(255, 255, 255);
  background: #275d73;
  box-shadow: 0px 0px 10px 1px rgba(59, 134, 247, 0.7);
  border-radius: 8px;

  &__button {
    padding: 16px 28px;
    margin-left: 20px;
    font-size: 18px;
    font-family: 'Larsseit';
    font-style: normal;
    font-weight: 700;
    color: rgb(255, 255, 255);
    background: rgb(28, 132, 57);
    border: none;
    border-radius: 8px;

    &:hover {
      cursor: pointer;
      background: rgba(28, 132, 57, 0.8);
      transition: all 0.3s linear;
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 10px 18px;
    font-size: 16px;

    .activate-vega__button {
      padding: 10px 20px;

      font-size: 16px;
    }
  }
}
</style>

<style lang="scss">
.dashbaord-analytics {
  .vs-list--title {
    font-size: 14px !important;
    color: #626262;
  }
  .vs-list--header {
    border: none !important;
    box-shadow: none !important;
  }
  .apexcharts-legend-text {
    font-weight: 600 !important;
    font-family: 'Lato', sans-serif !important;
    font-size: 14px !important;
    margin-left: 0.5rem !important;
  }
  .apexcharts-legend-series {
    margin-bottom: 0.5rem !important;
  }
  .mar-20 {
    margin-right: 20px;
  }
  .vs-tooltip {
    background: rgba(var(--vs-secondary), 1) !important;
    z-index: 100000;
  }

  .line-charts-container {
    * {
      box-sizing: border-box;
    }
    display: flex;

    padding: 10px;
    margin-top: -20px;

    @media (min-width: 1024px) {
      flex-direction: row;
      .margin-rt {
        margin-right: 0px;
      }
      .margin-lt {
        margin-left: 20px;
      }

      &--left {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        display: flex;
        flex-direction: column;

        > div {
          margin-bottom: 20px;
        }
      }

      &--right {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        display: flex;
        flex-direction: column;
        > div {
          margin-bottom: 20px;
        }
      }
    }

    @media (max-width: 1023px) {
      display: flex;
      flex-direction: column;
      .margin-rt {
        margin-right: 0px;
      }
      .margin-lt {
        margin-left: 0px;
      }
      &--left {
        > div {
          margin-bottom: 20px;
        }
      }

      &--right {
        > div {
          margin-bottom: 20px;
        }
      }
    }
  }

  .openscenter {
    margin-top: -60px;
  }

  .yearselect {
    width: 75px;
  }

  .slot {
    background-color: rgba(var(--vs-primary), 1) !important;
    padding: 0.8rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .dashboard-toolbar {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 1440px) {
      justify-content: space-between;
      flex-direction: column;
    }

    &-filters {
      @media (max-width: 991px) {
        width: 100%;
      }

      .filters {
        @media (max-width: 576px) {
          flex-direction: column;
        }
        &-container {
          margin-right: 20px;
          @media (max-width: 1440px) {
            margin-bottom: 20px;
          }

          @media (max-width: 991px) {
            margin-bottom: 15px;
          }
        }
      }
    }

    @media only screen and (max-width: 400px) {
      flex-wrap: wrap;
      justify-content: center;

      &-filters {
        .filters {
          &-container {
            margin-right: 0px;
          }
        }
      }
    }

    &-controls {
      @media (max-width: 1440px) {
        margin-bottom: 20px;
      }
      &-refresh {
        width: auto !important;
        &-btn {
          background: #275d73;
          box-shadow: 0px 0px 10px 1px rgba(39, 93, 115, 0.7);
          border: none;
          padding: 9.5px 30px;
          border-radius: 8px;
          cursor: pointer;

          &-wording {
            color: #fff;
            font-size: 1.3em;
            font-weight: 400;
            font-family: 'Lato';
          }
        }
      }
    }

    .disabled {
      opacity: 0.7;
      pointer-events: none;
      cursor: not-allowed;
    }
  }

  /*! rtl:begin:ignore */
  .border-orange {
    border-color: orange;
  }

  #dashboard-analytics {
    .greet-user {
      position: relative;

      .decore-left {
        position: absolute;
        left: 0;
        top: 0;
      }

      .decore-right {
        position: absolute;
        right: 0;
        top: 0;
      }
    }

    .timeline-icon {
      position: relative;
      top: 0;
      border-radius: 50%;
    }

    .dialog {
      left: 10px;
      display: inline;
      margin-right: 20px;
    }

    .dialog select {
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      box-shadow: none;
      font-family: 'Lato', Helvetica, Arial, sans-serif;
      font-weight: 400;
      width: 200px;
      line-height: 1.5;
      letter-spacing: 0.01rem;
    }

    @media (max-width: 576px) {
      .decore-left,
      .decore-right {
        width: 140px;
      }
    }
  }

  /*! rtl:end:ignore */

  .home-widgets {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .vs-table-text {
      box-shadow: none !important;
      font-size: 14px !important;
      color: #626262 !important;
    }

    .vx-card__body {
      padding: 12px 20px !important;
    }

    &-item {
      &--full-width {
        width: 100%;
      }
      &--one-third {
        width: 30.33%;
      }

      &--visitors-height {
        height: 309.75px;
      }

      width: 49%;

      @media (max-width: 769px) {
        width: 100%;
        margin-bottom: 15px;
      }

      @media (max-width: 813px) {
        width: 100%;
        margin-bottom: 15px;

        &-device-data {
          display: none;
        }
      }
    }
  }
}
</style>
